const base_url = $('meta[name=base-url]').attr('content')

$("#commonLoginFrm").on('submit', (function (e) {
    e.preventDefault();
    $("#error-message").html('');
    var frm = $('#commonLoginFrm');
    var btn = $('#commonLoginBtn');
    if (frm.valid()) {
        btn.prop('disabled', true);
        var showLoader = 'Processing...';
        showButtonLoader(btn, showLoader, 'disabled');
        populateCsrfToken();
        $.ajax({
            url: base_url + "/submit-login",
            type: "POST",
            data: frm.serialize(),
            success: function (data) {
                showButtonLoader(btn, showLoader, 'enable');
                window.location.href = data.redirectionUrl;
            },
            error: function (data) {
                var obj = data.responseJSON;
                    if (data.status === 422 || data.status === 429
                        ) {
                        for (var x in obj.errors) {
                            $('#commonLoginFrm [name=' + x + ']')
                                .closest('.form-group')
                                .find('.error-help-block')
                                .html(obj.errors[x].join('<br>'));
                                btn.prop('disabled', false);
                                btn.html('Login');
                        }
                    }else{
                       $('#error-message').html('');
                       var obj = jQuery.parseJSON(data.responseText);
                        $("#error-message").html(obj.message);
                        btn.prop('disabled', false);
                        btn.html('Login');
                    }
            },
        });
    }
}));

$("#commonLoginFrm input , select").on('focus, change , click',function (i, element) {
    $(this).closest('.form-group').find('.error-help-block').html('');
});
